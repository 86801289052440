<template>

    <div class="head-box" :style="{'background-color': isPage && Pageindex !=0? 'rgba(0,0,0,.7)':Pageindex !=0?'#fff':'','box-shadow':isPage?'0 5px 7px 0 rgba(0,0,0,.02)':''}">
        <div class="Navigation">
            <div class="box-lft">
                <!-- <div class="logo" @click="toHome" >
                    <img class="logo-image" src="https://supplychainimg.quanminyanxuan.com/other/b6df178830154b56a815962c44a4fd55.png" alt="">
                </div> -->
                <div class="logo" @click="toHome" >
                    <div class="logo-title">深圳市欧伟达贸易有限公司</div>
                    <!-- <img class="logo-image" src="https://supplychainimg.quanminyanxuan.com/other/b6df178830154b56a815962c44a4fd55.png" alt=""> -->
                </div>
            <ul class="ul-style" style="">
                <li><router-link :class="!isPage && Pageindex !=0?'fontcolor':''" to ="/"><div>首页</div></router-link></li>
<!--                <li><router-link to='about' :class="!isPage && Pageindex !=0?'fontcolor':''">入驻流程</router-link></li>-->
<!--                <li><router-link to='packCoupon' :class="!isPage && Pageindex !=0?'fontcolor':''">资质要求</router-link></li>-->
<!--                <li><router-link to='companyIntroduction' :class="!isPage && Pageindex !=0?'fontcolor':''">企业介绍</router-link></li>-->
            </ul>
<!--                <a href="https://order-prod.starseasz.com/#/login?source=1" target="_blank"  style="color:#fff;text-decoration: none;" id="test">-->
<!--                    <el-button type="primary" style="margin-left:80px;" color="#356cf6" round>-->
<!--                        登录/注册-->
<!--                    </el-button>-->
<!--                </a>-->
            </div>
        </div>

    </div>

</template>

<script>
import router from '@/router/index.js';
export default {
  props: {
    PageIndexs: {
        type:Number,
        default:null
    },
    AboutView:{
        type:Boolean,
        default:false
    },
    isNews:{
       type:Boolean,
        default:false
    }

  },
  data(){
    return {
        isPage: false,
        Pageindex:0,
        isNews:false
    }
  },
  watch: {

    PageIndexs(newVal, oldVal){
        if(newVal == 1 || newVal ==2 || newVal ==4 || newVal ==6){
            this.isPage=false
        }else{
            this.isPage=true
        }
        this.Pageindex=newVal
    },

  },
  mounted() {

      this.$nextTick(() =>{
          if(this.AboutView){
              this.Pageindex = 1
              this.isPage=false
          }
          if(this.isNews){
              this.Pageindex = 1
              this.isPage=false
          }

      })
  },

  methods: {
    toHome(){
        console.log('3333');
        this.$router.push({name: 'home'})
    },
    goTonews(){
        console.log('11111111')
        router.push({name:'news'})
    }
  },

};
</script>

<style scoped lang="scss">
.fontcolor{
    color: #000 !important;
}
.head-box {
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: height .35s cubic-bezier(.175, .885, .32, 1.275);
  transition: top .4s;
  border-bottom: 1px solid hsla(0, 0%, 100%, .2);
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  scrollbar-width: none;
  -ms-overflow-style: text;
  display: flex;
}

.head-box .Navigation{
    width: 1369px;
  height: 90px;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 auto;
  .ul-style {
    margin-left: 274px;

  }
}
.head-box .box-lft{
    display: flex;
    height: 90px;
    align-items: center;
}
.head-box .box-lft ul{
    margin-left: 125px;
    display: flex;
    width: 500px;
    height: 90px;
    align-items: center;
    justify-content: space-between;
}
.head-box .box-lft ul li{
    height: 90px;
    position: relative;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
}
.head-box .box-lft ul .about-us-hover:hover .about-us-div{
    display: block;
    height: 50px !important;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
}
.head-box .box-lft ul .about-us-hover:hover .about-us-title{
    display: block;
    height: 50px !important;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
}
.head-box .box-lft ul li .about-us-title{
    position: absolute;
    left: 0;
    top: 95px;
    width: 100%;
    height: 0px;
    line-height: 50px;
    background-color: #FFFFFF;
    text-align: center;
    z-index: 100;
    display: block;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    cursor: pointer;
    z-index: 99;
    overflow: hidden;
}
.head-box .box-lft ul li .about-us-div{
    display: block;
    position: fixed;
    left: 0;
    top: 90px;
    width: 100%;
    height: 0px;
    background-color: #FFFFFF;
    z-index: 10;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
}
.head-box .box-lft ul li .about-us-title span{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}
.is-active:after{
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    left: 50%;
    bottom: 25px;
    transform: translate(-50%,0);
    background-image: url(https://supplychainimg.quanminyanxuan.com/other/00177a8b79d448d8bb5f7e5dac2d2e26.jpg);
    background-size: 100% 100%;
}
.head-box .box-lft ul li a{
    display: inline-block;
    padding: 0 30px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    height: 90px;
    text-align: center;
    line-height: 90px;
}
.head-box .box-lft .logo{
    width: 653px;
    height: 38px;
    cursor: pointer;
    .logo-image {
        width: 155px;
    }
    .logo-title {
        font-size: 40px;
        font-family: ui-serif;
        color: #366cf3;
    }
}
.head-box .follow{
    height: 36px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}
.head-box .follow:hover .follow-code{
    display: block;
}
.head-box .follow .follow-div{
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}
.head-box .follow .follow-code{
    width: 138px;
    height: 148px;
    position: absolute;
    top: 42px;
    left: 50%;
    transform: translate(-50%);
    display: none;
}
.head-box .follow .follow-div img{
    width: 26px;
    height: 20px;
}
.head-box .follow .follow-div span{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 38px;
    margin-left: 5px;

}
</style>
